import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Redirect
} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { Slider, Switch as UiSwitch } from '@material-ui/core';
import { useTimer } from 'react-timer-hook';

import Drawing from './Drawing';

import avatarInconnu from './avatarInconnu.png';
import './App.css';

// const socket = io('http://192.168.1.17:3001');
const socket = io('https://jpoupinet.com:3001');

const PlayerList = ({ players, curPlayer, changeAvatar }) => {
  return (
    <div id="players">
      <p id="header">Liste des joueurs</p>
      <div>
        {players.map(player => {
          if (player.id === curPlayer.id) {
            return (
              <div className={player.devineur ? 'player player-devineur' : 'player'} >
                <div
                  className="avatar"
                  style={{ display: 'inline-block', marginRight: '10px', cursor: 'pointer' }}
                  title="Modifier mon avatar"
                  onClick={() => changeAvatar()}
                >
                  <img src={player.avatar || avatarInconnu} alt="Avatar" />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <p id="curPlayer" key={player.id}>{player.name}</p>
                  {
                    player.devineur &&
                    <p id="joueurActif">↑ Joueur actif / Joueuse active</p>
                  }
                </div>
              </div>
            );
          }

          return (
            <div
              key={player.id}
              className={
                player.online ?
                  ('player' + (player.devineur ? ' player-devineur' : ''))
                  :
                  'player-offline'
              }
            >
              <div className="avatar" style={{ display: 'inline-block', marginRight: '10px' }}>
                <img src={player.avatar  || avatarInconnu} alt="Avatar" />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <p id="nomJoueurActif">
                  {player.name}
                  {!player.online && ' - Déconnecté(e)'}
                </p>
                {
                  player.devineur &&
                  <p id="joueurActif">↑ Joueur actif / Joueuse active</p>
                }
              </div>
            </div>
          );
        })}
      </div>
    </div >
  );
}

const OptionsGame = () => {
  const [timerSwitch, setTimerSwitch] = useState(false);

  const changeTimerSwitch = () => {
    setTimerSwitch(!timerSwitch);
    socket.emit('changeOptionsTimer', !timerSwitch);
  };

  const changeTimerDuration = duration => {
    socket.emit('changeOptionsTimerDuration', duration);
  };

  const marks = [
    {
      value: 5,
      label: '5s'
    },
    {
      value: 60,
      label: '1 min'
    },
    {
      value: 120,
      label: '2 min'
    },
    {
      value: 180,
      label: '3 min'
    },
    {
      value: 240,
      label: '4 min'
    },
    {
      value: 300,
      label: '5 min'
    }
  ];

  return (
    <div id="options">
      <h3>Options de la partie</h3>
      <label>
        <p>
          {`Activer le timer (actif pendant les phases de proposition d'indices 
            et de proposition de réponse)`}
        </p>
        <UiSwitch
          checked={timerSwitch}
          onChange={() => changeTimerSwitch()}
          color="primary"
        />
      </label>
      <p>Timer (en secondes)</p>
      <Slider
        defaultValue={60}
        step={5}
        valueLabelDisplay="auto"
        min={5}
        max={300}
        marks={marks}
        onChangeCommitted={(e, val) => changeTimerDuration(val)}
        disabled={!timerSwitch}
      />
    </div>
  );
}

const Timer = ({ expiryTimestamp, onExpire }) => {
  const {
    seconds,
    minutes
  } = useTimer({ expiryTimestamp, onExpire });

  return (
    <div className="timer">
      <p>{`Temps restant : ${minutes < 10 ? '0' + minutes : minutes} :
        ${seconds < 10 ? '0' + seconds : seconds}`}</p>
    </div>
  );
};

const Game = () => {
  const idGame = useParams().roomName;

  const [gameInitializedLocal, setGameInitializedLocal] = useState(false);
  const [curPlayer, setCurPlayer] = useState(null);
  const [players, setPlayers] = useState([]);
  const [gameState, setGameState] = useState({});
  const [cartes, setCartes] = useState([]);
  const [clue, setClue] = useState('');
  const [guess, setGuess] = useState('');
  const [leaderboard, setLeaderboard] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [options, setOptions] = useState({});
  const [changeWordSuggested, setChangeWordSuggested] = useState('');
  const [periodeLeaderboard, setPeriodeLeaderboard] = useState(30);

  const inputClue = useRef(null);

  const startGame = () => {
    if (players.filter(p => p.online).length < 4) return;

    socket.emit('startGame');
  };

  const devineurChoseNumber = number => {
    socket.emit('devineurChoseNumber', number);
  };

  const handleClue = event => {
    setClue(event.target.value);
  };

  const handleSubmitClue = e => {
    e.preventDefault();
    socket.emit('submitClue', clue);
    setClue('');
  };

  const handleGuess = event => {
    setGuess(event.target.value);
  };

  const handleSubmitGuess = e => {
    e.preventDefault();
    socket.emit('submitGuess', guess);
    setGuess('');
  };

  const timerSubmitClue = () => {
    socket.emit('submitClue', inputClue.current.value);
    setClue('');
  };

  useEffect(() => {
    const userToken = localStorage.getItem('userToken');

    if (!userToken) {
      setRedirect(true);
    } else {
      socket.on('roomAlreadyExists', () => {
        setRedirect(true);
      });

      socket.on('connectedToRoom', data => {
        setCartes(data.cartes)
        setCurPlayer(data.curPlayer);
        setOptions(data.options);
        setGameState(data.gameState);
        setPlayers(data.players);
        setGameInitializedLocal(true);
      });

      socket.on('gameStateChange', data => {
        console.log(data);

        if (data.gameState.step === 'chooseNumber') {
          setCartes(data.cartes);
          setChangeWordSuggested('');
        }

        setPlayers(data.players);
        setOptions(data.options);
        setGameState(data.gameState);
      });

      socket.on('changeWordSuggested', data => {
        setChangeWordSuggested(data);
      });

      socket.on('changeWordConfirm', data => {
        setChangeWordSuggested('');
      });

      socket.on('sendLeaderboard', data => {
        setLeaderboard(data);
      });

      if (!gameInitializedLocal) {
        socket.emit('connectToRoom', {
          idGame,
          userToken: JSON.parse(userToken),
          connectTime: Date.now()
        });
      }
    }
  }, []);

  return (
    <div id="game">
      {redirect && <Redirect to={'/' + idGame} />}
      {
        gameInitializedLocal &&
        <PlayerList
          players={players}
          curPlayer={curPlayer}
          changeAvatar={() => setRedirect(true)}
        />
      }
      <div id="gameZone">
        {
          gameInitializedLocal &&
          !gameState.started &&
          <div>
            <h1>Just One</h1>
            <h3>4 joueurs minimum</h3>
            <h3>Nombre de joueurs maximum recommandé : 7</h3>
            <p>
              <label>
                Lien de la partie à partager :
                <input
                  id="lienPartie"
                  type="text"
                  value={window.location.href}
                  onFocus={(e) => e.target.select()}
                  readOnly
                />
              </label>
            </p>
            <button
              onClick={() => startGame()}
              disabled={!curPlayer.master}
            >
              Commencer
            </button>
            {
              !curPlayer.master &&
              <p>{`Seul Le joueur qui a créé la partie peut la lancer.`}</p>
            }
            {
              curPlayer.master &&
              players.map(p => p.online).length < 4 &&
              <p className="message-rouge">
                {`Il faut au moins 4 joueurs connectés 
                  avant de pouvoir lancer la partie.`}
              </p>
            }
            {
              curPlayer.master &&
              <OptionsGame />
            }
          </div>
        }
        {
          gameInitializedLocal &&
          gameState.step === 'chooseNumber' &&
          players.find(p => p.id === curPlayer.id).devineur &&
          <div>
            <h3>Choisis un chiffre de 1 à 5</h3>
            <button onClick={() => devineurChoseNumber(1)}>1</button>
            <button onClick={() => devineurChoseNumber(2)}>2</button>
            <button onClick={() => devineurChoseNumber(3)}>3</button>
            <button onClick={() => devineurChoseNumber(4)}>4</button>
            <button onClick={() => devineurChoseNumber(5)}>5</button>
          </div>
        }
        {
          gameInitializedLocal &&
          gameState.step === 'chooseNumber' &&
          !players.find(p => p.id === curPlayer.id).devineur &&
          <div>
            <h3>
              {players.find(p => p.devineur === true).name} choisit un chiffre.
            </h3>
            {cartes[gameState.round - 1].map((carte, i) =>
              <p key={'mot' + (i + 1)}>{i + 1} : {carte.toUpperCase()}</p>
            )}
          </div>
        }
        {
          gameInitializedLocal &&
          gameState.step === 'enterClue' &&
          (
            players.find(p => p.id === curPlayer.id).devineur
            ||
            gameState.clues.findIndex(c => c.playerId === curPlayer.id) > -1
          ) &&
          <div>
            {
              players.filter(p =>
                !gameState.clues.map(c => c.playerId)
                  .includes(p.id) && !p.devineur
              ).map(p => (
                <p key={`enterClue${p.id}`}>
                  {`${p.name} cherche un indice ...`}
                </p>
              ))
            }
            {
              options.timer &&
              <Timer
                expiryTimestamp={
                  (gameState.timerStart - curPlayer.timeDiff) +
                  (options.timerDuration * 1000)
                }
                onExpire={() => null}
              />
            }
            {
              changeWordSuggested.length > 0 &&
              <p className="bold">
                {`${changeWordSuggested} a suggéré un changement de mot.`}
              </p>
            }
            {
              changeWordSuggested.length > 0 &&
              players.find(p => p.id === curPlayer.id).devineur &&
              <button onClick={() => socket.emit('changeWord')}>
                Changer de mot
              </button>
            }
          </div>

        }
        {
          gameInitializedLocal &&
          gameState.step === 'enterClue' &&
          !players.find(p => p.id === curPlayer.id).devineur &&
          gameState.clues.findIndex(c => c.playerId === curPlayer.id) === -1 &&
          <div>
            <h3>Le mot choisi est : {gameState.word.toUpperCase()}</h3>
            <form onSubmit={handleSubmitClue}>
              <p>
                <label>
                  Entrez un indice :
                  <input
                    type="text"
                    value={clue}
                    onChange={handleClue}
                    ref={inputClue}
                  />
                </label>
              </p>
              <input type="submit" value="Valider" />
              {
                options.timer &&
                <Timer
                  expiryTimestamp={
                    (gameState.timerStart - curPlayer.timeDiff) +
                    (options.timerDuration * 1000)
                  }
                  onExpire={timerSubmitClue}
                />
              }
              {
                players.filter(p =>
                  !gameState.clues.map(c => c.playerId)
                    .includes(p.id) && !p.devineur
                ).map(p => (
                  <p key={`enterClue${p.id}`}>
                    {`${p.name} cherche un indice ...`}
                  </p>
                ))
              }
              {
                changeWordSuggested.length === 0 &&
                <button onClick={e => {
                  e.preventDefault();
                  socket.emit('suggestChangeWord');
                }}>
                  Suggérer un changement de mot
                </button>
              }
              {
                changeWordSuggested.length > 0 &&
                <p className="bold">
                  {`${changeWordSuggested} a suggéré un changement de mot.`}
                </p>
              }
            </form>
          </div>
        }
        {
          gameInitializedLocal &&
          gameState.step === 'compareClues' &&
          players.find(p => p.id === curPlayer.id).devineur &&
          <div>Les autres joueurs éliminent les indices identiques.</div>
        }
        {
          gameInitializedLocal &&
          gameState.step === 'compareClues' &&
          !players.find(p => p.id === curPlayer.id).devineur &&
          <div>
            <h3>
              Sélectionnez les indices identiques ou trop
              similaires pour les éliminer
            </h3>
            <p>Vert = OK</p>
            <p>Rouge = indice à éliminer</p>
            {gameState.clues.map((clue, i) =>
              <p
                key={clue + i}
                onClick={() => socket.emit('selectClue', clue)}
                className={clue.selected ? 'clue-selected' : 'clue'}
              >
                {`${clue.clue.toUpperCase()} - proposition de 
                  ${players.find(p => p.id === clue.playerId).name}`}
              </p>
            )}
            <button onClick={() => socket.emit('submitCheckedClues')}>
              Valider
            </button>
          </div>
        }
        {
          gameInitializedLocal &&
          gameState.step === 'devineurSeeClues' &&
          <div>
            <h2>
              {players.find(p => p.devineur).name} est en train de répondre.
            </h2>
            <h3>Les indices sont : </h3>
            {gameState.clues.filter(clue => !clue.selected).map((clue, i) =>
              <p key={clue + i}>
                {`${clue.clue.toUpperCase()} - proposition de 
                  ${players.find(p => p.id === clue.playerId).name}`}
              </p>
            )}
            {
              players.find(p => p.id === curPlayer.id).devineur &&
              <div>
                <form onSubmit={handleSubmitGuess}>
                  <p>
                    <label>
                      Deviner le mot :
                      <input type="text" value={guess} onChange={handleGuess} />
                    </label>
                  </p>
                  <input id="validerGuess" type="submit" value="Valider" />
                  <button
                    id="skipGuess"
                    onClick={e => {
                      e.preventDefault();
                      socket.emit('skipGuess');
                      setGuess('');
                    }}
                  >
                    Passer
                  </button>
                </form>
                <p>En cas de mauvaise réponse,
                  une carte de la pioche sera perdue.</p>
                <p>Si vous passez, aucune carte ne sera perdue,
                  mais vous ne gagnerez aucun point.</p>
              </div>
            }
            {
              options.timer &&
              <Timer
                expiryTimestamp={
                  (gameState.timerStart - curPlayer.timeDiff) +
                  (options.timerDuration * 1000)
                }
                onExpire={() => {
                  socket.emit('skipGuess');
                  setGuess('');
                }}
              />
            }
          </div>
        }
        {
          gameInitializedLocal &&
          (
            gameState.step === 'roundWon' ||
            gameState.step === 'roundLost' ||
            gameState.step === 'roundSkipped'
          ) &&
          <div>
            <h2>Le mot à deviner était : {gameState.word.toUpperCase()}</h2>
            {
              gameState.step !== 'roundSkipped' &&
              <p>
                {players.find(p => p.devineur).name + ' '}
                a proposé : {gameState.guess}
              </p>
            }
            {gameState.step === 'roundWon' &&
              <h3 id="bienJoue">Bien joué ! + 1 point</h3>}
            {
              gameState.step === 'roundSkipped' &&
              <h3 id="passe">{players.find(p => p.devineur).name} a passé.</h3>
            }
            {gameState.step === 'roundLost' &&
              <h3 id="dommage">Dommage</h3>}
            {
              <button onClick={() => socket.emit('nextRound')}>
                Manche suivante
              </button>
            }
          </div>
        }
        {
          gameInitializedLocal &&
          gameState.step === 'gameEnd' &&
          <div>
            <h2>Partie terminée</h2>
            <h3>Score final : {gameState.score} / 13</h3>
            {gameState.score === 13 &&
              <h3>Score parfait ! Y arriverez-vous encore ?</h3>}
            {gameState.score === 12 &&
              <h3>Incroyable ! Vos amis doivent être impressionnés !</h3>}
            {gameState.score === 11 &&
              <h3>Génial ! C'est un score qui se fête !</h3>}
            {(gameState.score === 9 || gameState.score === 10) &&
              <h3>Waouh, pas mal du tout !</h3>}
            {(gameState.score === 7 || gameState.score === 8) &&
              <h3>Vous êtes dans la moyenne. Arriverez vous à faire mieux ?</h3>}
            {(gameState.score >= 4 && gameState.score <= 6) &&
              <h3>C'est un bon début. Réessayez !</h3>}
            {(gameState.score >= 0 && gameState.score <= 3) &&
              <h3>Essayez encore.</h3>}
            <button onClick={() => startGame()} disabled={!curPlayer.master}>
              Lancer une nouvelle partie
            </button>
            {
              leaderboard.length > 0 &&
              <div>
                <h3>Tableau des meilleurs scores</h3>
                {
                  periodeLeaderboard ?
                    <div id="choixLeaderboard">
                      <h4>{periodeLeaderboard} derniers jours</h4>
                      <h4><button onClick={() => setPeriodeLeaderboard(null)}>
                        De tous les temps
                      </button></h4>
                    </div>
                    :
                    <div id="choixLeaderboard">
                      <h4><button onClick={() => setPeriodeLeaderboard(30)}>
                        30 derniers jours
                      </button></h4>
                      <h4>De tous les temps</h4>
                    </div>
                }
                <ul>
                  {
                    leaderboard
                      .filter(l => {
                        if (!periodeLeaderboard) return true;
                        return l.date >= Date.now() - (periodeLeaderboard * 24 * 60 * 60);
                      })
                      .sort((a, b) => {
                        if (a.score > b.score) return 1;
                        if (a.score < b.score) return -1;

                        if (!a.timer && !b.timer) {
                          if (a.date > b.date) return 1;
                          if (a.date <= b.date) return -1;
                        }
                        
                        if (!a.timer) return 1;
                        if (!b.timer) return -1;

                        if (a.timer < b.timer) return -1;
                        if (a.timer > b.timer) return 1;
                        
                        return 0;
                      })
                      .slice(0, 5)
                      .map((l, i) => (
                        <li key={'leaderboard' + i}>
                          {`
                            Score : ${l.score}/13 - 
                            Timer : ${l.timer ? l.timer : 'désactivé'} - 
                            ${l.players.join(', ')}
                          `}
                        </li>
                      ))
                  }
                </ul>
              </div>
            }
            {
              curPlayer.master &&
              <OptionsGame />
            }
          </div>
        }
        {
          gameInitializedLocal &&
          gameState.started &&
          gameState.step !== 'gameEnd' &&
          <div id="score">
            <p>Score : {gameState.score} / 13</p>
            <p>Il reste {13 - gameState.round} carte(s) dans la pioche.</p>
          </div>
        }
      </div>
    </div>
  );
};

const Accueil = () => {
  const oldAvatar = localStorage.getItem('userToken') ?
    JSON.parse(localStorage.getItem('userToken')).avatar : null;

  const [roomName, setRoomName] = useState(useParams().roomName || '');
  const [userName, setUserName] = useState(localStorage.getItem('userToken') ?
    JSON.parse(localStorage.getItem('userToken')).name : '');
  const [avatar, setAvatar] = useState(oldAvatar);
  const [changeAvatar, setChangeAvatar] = useState(false);
  const [redirect, setRedirect] = useState('');

  const handleRoomName = event => {
    setRoomName(event.target.value);
  };
  const handleUserName = event => {
    setUserName(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (roomName.trim().length === 0 || userName.trim().length === 0) return;

    const idUser = localStorage.getItem('userToken') ?
      JSON.parse(localStorage.getItem('userToken')).id : uuidv4();

    localStorage.setItem(
      'userToken',
      JSON.stringify({ name: userName, id: idUser, avatar })
    );
    setRedirect(roomName);
  };

  return (
    <div id="accueil">
      {
        redirect.trim().length > 0 &&
        <Redirect to={'/game/' + redirect} />
      }
      <form onSubmit={handleSubmit}>
        <h1 className="logo">Just One</h1>
        <div>
          <label>
            Nom de la partie :
            <input type="text" value={roomName} onChange={handleRoomName} />
          </label>
        </div>
        <div>
          <label>
            Votre nom :
            <input type="text" value={userName} onChange={handleUserName} />
          </label>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label>
            Dessinez votre avatar (optionnel) :
          </label>
          {
            oldAvatar && !changeAvatar &&
            <div style={{ display: 'flex', flexDirection: 'column' }} >
              <p style={{ color: 'white' }}>Avatar précédent</p>
              <div className="avatar">
                <img src={oldAvatar} alt="Avatar" />
              </div>
              <button onClick={() => setChangeAvatar(true)}>Changer</button>
            </div>
          }
          {
            (!oldAvatar || changeAvatar) &&
            <Drawing setAvatar={dessin => setAvatar(dessin)} />
          }
        </div>
        <input type="submit" value="Créer / Rejoindre une partie" />
      </form>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Accueil />
        </Route>
        <Route exact path="/:roomName">
          <Accueil />
        </Route>
        <Route path="/game/:roomName">
          <Game />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
